import React from 'react';
import {Box, Button, Typography} from "@mui/material";

const NegotiateStep4 = ({ onBack }) => {

  return (
    <Box padding={3}>
      <Typography gutterBottom sx={{fontWeight: 'bold'}}>
        Freedom Cash Lenders
        <br/>
        2726 Mission Rancheria Rd
        <br/>
        Lakeport, CA 95453
      </Typography>
      <Typography gutterBottom sx={{fontWeight: 'bold'}}>
        Dan Chelew
        <br/>
        4444 Charlo ST
        <br/>
        JACKSONVILLE, FL, 32226
      </Typography>
      <Typography gutterBottom sx={{fontWeight: 'bold'}}>
        Sep 8, 2023
        <br/>
        11816641859
      </Typography>
      <Typography gutterBottom>
        Current Balance: <strong>$15.00</strong>
        <br/>
        Resolution Amount: <strong>$7.50</strong>
        <br/>
      </Typography>
      <Typography gutterBottom>
        This is confirmation that Freedom Cash Lenders has agreed to accept
        <strong>$7.50</strong> to resolve the above account in full. This offer
        is contingent
        upon receipt of <strong>$7.50</strong> as outlined below.
      </Typography>

      <Typography>
        Terms:
      </Typography>

      <Typography gutterBottom>
        $4.22 due on Sep 11, 2023
        <br/>
        $3.28 due on Oct 11, 2023
      </Typography>
      <Typography gutterBottom>
        Once all payments have been confirmed by <strong>Freedom
        Cash Lenders</strong> and cleared in accordance with the terms of this
        offer, the account will be considered resolved. <strong>Freedom
        Cash Lenders</strong> will update the creditor to reflect a
        "Settle for Less" status. If either party provides information to the
        credit bureaus, updates reflecting the settlement will be sent at that
        time. Additionally, upon your request to Freedom Cash Lenders, a letter
        confirming that your account has been settled in full will be forwarded
        to
        you.*
      </Typography>
      <Typography gutterBottom>
        If scheduled payments are not confirmed on or before the 11th of the
        month or should a payment not clear, the terms of this offer may become
        null and void and the full balance shall be due.
      </Typography>
      <Typography gutterBottom>
        *Allow 30-60 days to receive settled in full letter.
        <br/>
        This communication is from <strong>Freedom
        Cash Lenders</strong>.
      </Typography>
      <Button variant="outlined" onClick={onBack}>
        Go back
      </Button>
    </Box>
  )
};

export default NegotiateStep4;
