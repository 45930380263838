import React from "react";
import {Stack} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DashboardPayCard from "./shared/DashboardPayCard";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DashboardCardLoading from "./shared/DashboardCardLoading";

const NegotiateToPayLessBlock = ({user, loan}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (!user || !loan) {
    return (<DashboardCardLoading />);
  }

  return (
    <Stack direction='column'
           spacing={1}
           paddingBottom={2}
           style={{
             display: "flex",
             justifyContent: "center",
             alignItems: isSm ? "center" : "start"
           }}>
      <DashboardPayCard icon={<QuestionAnswerIcon size={48}/>}
                        title='Negotiate to Pay Less'
                        description='Cannot pay your balance in full? Negotiate online to resolve your debt for less than you owe'
                        link='/negotiate-to-pay-less'/>
    </Stack>
  );
};

export default NegotiateToPayLessBlock;
