import React from "react";
import {Alert, Box, Typography} from "@mui/material";

const OfferPlaceholder = () => {
  return (
    <Box sx={{p: 2, backgroundColor: "#f7f7f7", height: "100%"}}>
      <Typography variant="h5" align="center" gutterBottom>
        Offer Summary
      </Typography>
      <Typography align="center">
        Fill in the fields and click <strong>"Calculate an
        offer"</strong> button to calculate your offer.
      </Typography>
      <Typography align="center" paddingTop={3}>
        If you can not accept one of these offers presented, you can try the
        increase your last offer. Should your increased offer still not be
        accepted, <strong>Freedom Cash Lenders may present additional
        offers </strong>.
      </Typography>
    </Box>
  );
};

export default OfferPlaceholder;
