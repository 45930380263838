import React, {useEffect, useState} from 'react';
import {
  Stepper,
  Step,
  StepLabel, Grid
} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {connect, useDispatch} from "react-redux";
import NegotiateStep1 from "./NegotiateStep1";
import {loadLoanData} from "../../actions/loanActions";
import StepperLoading from "../dashboard/shared/StepperLoading";
import NegotiateStep2 from "./NegotiateStep2";
import NegotiateStep3 from "./NegotiateStep3";
import NegotiateStep4 from "./NegotiateStep4";
import {loadPaymentData} from "../../actions/paymentActions";
import {useParams} from "react-router-dom";
import OfferSummary from "./OfferSummary";

const steps = ['Setup plan', 'Billing Contact', 'Payment', 'Confirm'];

const frequency = [
  {value: 'weekly', label: 'Weekly'},
  {value: 'bi-weekly', label: 'Bi-Weekly'},
  {value: 'monthly', label: 'Monthly'}
];

const mapStateToProps = (state) => ({
  loan: state.loan.data,
  billing: state.payment.billing,
  paymentMethods: state.payment.paymentMethods
});

const NegotiateToPayLess = ({loan, billing, paymentMethods}) => {
  const [firstPaymentDate, setFirstPaymentDate] = useState(null);
  const [amount, setAmount] = useState('');
  const [numberOfPayments, setNumberOfPayments] = useState('');
  const [paymentFrequency, setPaymentFrequency] = useState(frequency[0].value);
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const {type} = useParams();

  useEffect(() => {
    if (!loan) {
      dispatch(loadLoanData());
    }
    if (!billing) {
      dispatch(loadPaymentData());
    }
  }, [dispatch, loan, billing]);

  if (!loan) {
    return (<StepperLoading/>);
  }

  const validateAmount = () => {
    return amount && parseInt(amount, 10) >= 5;
  };

  const handlePaymentFrequencyChange = (event) => {
    setPaymentFrequency(event.target.value);
  };

  const maxPaymentsAllowed = () => {
    switch (paymentFrequency) {
      case 'weekly':
        return 52;
      case 'bi-weekly':
        return 26;
      case 'monthly':
        return 12;
      default:
        return 0;
    }
  };

  const validateNumberOfPayments = () => {
    const num = parseInt(numberOfPayments, 10);
    return num > 0 && num <= maxPaymentsAllowed();
  };

  const handleNumberOfPaymentsChange = (event) => {
    setNumberOfPayments(event.target.value.replace(/[^0-9]/g, ''));
  };

  const getPaymentFrequencyHelperText1 = () => {
    if (paymentFrequency === 'weekly') {
      return "Enter the amount you can afford weekly";
    } else if (paymentFrequency === 'bi-weekly') {
      return "Enter the amount you can afford bi-weekly";
    } else {
      return "Enter the amount you can afford monthly";
    }
  };

  const getPaymentFrequencyHelperText2 = () => {
    if (paymentFrequency === 'weekly') {
      return "Up to 52 payments weekly allowed by your creditor";
    } else if (paymentFrequency === 'bi-weekly') {
      return "Up to 26 payments bi-weekly allowed by your creditor";
    } else if (paymentFrequency === 'monthly') {
      return "Up to 12 payments monthly allowed by your creditor";
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid sx={{maxWidth: 960, mx: 'auto', width: '100%', padding: 4}}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && <NegotiateStep1 setActiveStep={setActiveStep}
                                             loan={loan}
                                             amount={amount}
                                             setAmount={setAmount}
                                             validateAmount={validateAmount}
                                             numberOfPayments={numberOfPayments}
                                             handleNumberOfPaymentsChange={handleNumberOfPaymentsChange}
                                             getPaymentFrequencyHelperText1={getPaymentFrequencyHelperText1}
                                             validateNumberOfPayments={validateNumberOfPayments}
                                             maxPaymentsAllowed={maxPaymentsAllowed}
                                             firstPaymentDate={firstPaymentDate}
                                             setFirstPaymentDate={setFirstPaymentDate}
                                             onNext={handleNext}
                                             activeStep={activeStep}
        />}
        {activeStep === 1 && (
          <>
            <NegotiateStep2
              setActiveStep={setActiveStep}
              billing={billing}
              amountToPay={amount}
              onBack={handleBack}
              onNext={handleNext}
              activeStep={activeStep}
            />
          </>
        )}
        {activeStep === 2 && <NegotiateStep3
          paymentType={type}
          billing={billing}
          paymentMethods={paymentMethods}
          amountToPay={amount}
          onBack={handleBack}
          onNext={handleNext}
          activeStep={activeStep}
        />}
        {activeStep === 3 &&
          <NegotiateStep4 onBack={handleBack}/>}
      </Grid>
    </LocalizationProvider>
  );
}

export default connect(mapStateToProps)(NegotiateToPayLess);

