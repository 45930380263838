import React from "react";
import {
    Box, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Typography
} from "@mui/material";

const OfferSummary = ({activeStep, offerStatus}) => {

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
            year: "2-digit"
        });
    };

    const payments = [
        {id: 1, date: "2024-08-22", amount: 7},
        {id: 2, date: "2024-09-22", amount: 7},
        {id: 3, date: "2024-10-22", amount: 9},
        {id: 4, date: "2024-11-22", amount: 9},
        {id: 5, date: "2024-11-23", amount: 10},
        {id: 6, date: "2024-11-23", amount: 11},
    ];

    return (
        <Box sx={{p: 2, backgroundColor: '#f7f7f7', height: '100%'}}>

            {(activeStep === 1 || activeStep === 2) ? (
                <Typography variant="h5" align="center" gutterBottom>
                    Offer Summary
                </Typography>
            ) : activeStep === 0 ? (
                <Typography variant="h5" align="center" gutterBottom>
                    My offer summary
                </Typography>
            ) : null}

            {offerStatus ? (
                    <Box sx={{p: 2, textAlign: "center"}}>
                        <Typography gutterBottom>
                            Attempt to resolve this account in full for $1.00
                        </Typography>
                        <Typography pb={3}>
                            The payment will be processed on the 8th or the next business day, starting Today (Sep 8, 2023).
                        </Typography>
                    </Box>
            ) : (
                <Box sx={{p: 2, textAlign: "center"}}>
                    <Typography>
                        Your original offer was not accepted. We present you a counter offer.
                        Please review and decide whether to accept it or modify your proposal.
                    </Typography>
                </Box>
            )}

            <TableContainer component={Paper} sx={{maxHeight: 250}}>
                <Table sx={{minWidth: 200}} size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><strong></strong></TableCell>
                            <TableCell align="center"><strong>Date</strong></TableCell>
                            <TableCell align="center"><strong>Amount</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payments.map(({id, date, amount}) => (
                            <TableRow key={id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell align="center">{id}</TableCell>
                                <TableCell align="center">{formatDate(date)}</TableCell>
                                <TableCell align="center">{amount}$</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{display: "flex", justifyContent: "space-between", padding: "8px 36px"}}>
                <Typography variant="body1" fontSize={13}><strong>Total</strong></Typography>
                <Typography variant="body1" fontSize={13}>
                    <strong>{payments.reduce((acc, p) => acc + p.amount, 0)}$</strong>
                </Typography>
            </Box>
        </Box>
    );
};

export default OfferSummary;
