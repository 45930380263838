import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@mui/material";
import * as React from "react";
import states from "../../assets/states.json";
import PaymentAmount from "../payment/PaymentAmount";
import OfferSummary from "./OfferSummary";

const NegotiateStep2 = ({billing, amountToPay, activeStep, onBack, onNext}) => {

  const [details, setDetails] = React.useState(billing || {});

  React.useEffect(() => {
    setDetails(billing);
  }, [billing]);

  const handleSaveChange = (e) => {
    setDetails({...details, exist: e.target.checked});
  };

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    setDetails((prev) => ({...prev, [name]: value}));
  };

  return (
    <Grid container marginTop={2} paddingTop={2}>
      <Grid item sm={8} xs={12} padding={1}>
        <Paper elevation={2} style={{padding: "2rem"}}>
          <Typography variant='h5' my={2}>Billing Contact</Typography>
          <form
            onSubmit={onNext}
            autoComplete='off'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id='firstName'
                  name='firstName'
                  label='First name'
                  fullWidth
                  onChange={handleInputChange}
                  value={details.firstName || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id='lastName'
                  name='lastName'
                  label='Last name'
                  fullWidth
                  onChange={handleInputChange}
                  value={details.lastName || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id='address'
                  name='streetAddress'
                  label='Address line'
                  fullWidth
                  onChange={handleInputChange}
                  value={details.streetAddress || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id='city'
                  name='city'
                  label='City'
                  fullWidth
                  onChange={handleInputChange}
                  value={details.city || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  required
                  fullWidth>
                  <InputLabel>State</InputLabel>
                  <Select
                    name='state'
                    label='State'
                    value={details.state || ""}
                    onChange={handleInputChange}
                  >
                    {states.map((option) => (
                      <MenuItem key={option.abbreviation}
                                value={option.abbreviation}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id='zip'
                  name='zipCode'
                  label='Zip / Postal code'
                  fullWidth
                  inputProps={{maxLength: 5, pattern: "[0-9]*"}}
                  onChange={handleInputChange}
                  value={details.zipCode || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox color='secondary'
                                     name='exist'
                                     checked={details.exist || false}
                                     onChange={handleSaveChange}/>}
                  label='Save this address for next payments'
                />
              </Grid>
            </Grid>
            <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
              <Button color='inherit' onClick={onBack} sx={{mr: 1}}>
                Back
              </Button>
              <Box sx={{flex: "1 1 auto"}}/>
              <Button type='submit'>Next</Button>
            </Box>
          </form>
        </Paper>
      </Grid>
      <Grid item sm={4} xs={12} padding={1}>
        <PaymentAmount amount={amountToPay}/>
        <OfferSummary activeStep={activeStep}/>
      </Grid>
    </Grid>

  );
};

export default NegotiateStep2;
