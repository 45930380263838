import {
    Alert,
    Box,
    Button, FormControl, FormHelperText,
    Grid, InputLabel, MenuItem, Paper, Select,
    TextField,
    Typography
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import React, {useState} from "react";
import StepperLoading from "../dashboard/shared/StepperLoading";
import OfferSummary from "./OfferSummary";
import OfferPlaceholder from "./OfferPlaceholder";

const frequency = [
    {value: 'weekly', label: 'Weekly'},
    {value: 'bi-weekly', label: 'Bi-Weekly'},
    {value: 'monthly', label: 'Monthly'}
];

const NegotiateStep1 = ({loan, onNext, activeStep}) => {

    const [firstPaymentDate, setFirstPaymentDate] = useState(null);
    const [amount, setAmount] = useState('');
    const [numberOfPayments, setNumberOfPayments] = useState('');
    const [paymentFrequency, setPaymentFrequency] = useState(frequency[0].value);
    const [offerCalculationProcess, setOfferCalculationProcess] = useState(false);
    const [offerStatus, setOfferStatus] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const validateAmount = () => {
        return amount && parseInt(amount, 10) >= 5;
    };

    const handlePaymentFrequencyChange = (event) => {
        setPaymentFrequency(event.target.value);
    };

    const maxPaymentsAllowed = () => {
        switch (paymentFrequency) {
            case 'weekly':
                return 52;
            case 'bi-weekly':
                return 26;
            case 'monthly':
                return 12;
            default:
                return 0;
        }
    };

    const handleCalculateOffer = () => {
        setOfferCalculationProcess(true);
        setShowAlert(true);
    };

    const validateNumberOfPayments = () => {
        const num = parseInt(numberOfPayments, 10);
        return num > 0 && num <= maxPaymentsAllowed();
    };

    const handleNumberOfPaymentsChange = (event) => {
        setNumberOfPayments(event.target.value.replace(/[^0-9]/g, ''));
    };

    const getPaymentFrequencyHelperText1 = () => {
        if (paymentFrequency === 'weekly') {
            return "Enter the amount you can afford weekly";
        } else if (paymentFrequency === 'bi-weekly') {
            return "Enter the amount you can afford bi-weekly";
        } else {
            return "Enter the amount you can afford monthly";
        }
    };

    if (!loan) {
        return (<StepperLoading/>);
    }
    return (
        <>
            <Grid container marginTop={2} paddingTop={2}>
                <Grid item sm={8} xs={12} padding={1}>
                    <Paper elevation={2} style={{padding: "2rem"}}>
                        <Box paddingBottom={2}>
                            <Typography variant="h5" gutterBottom>
                                Create your offer to creditor
                            </Typography>
                            <Typography gutterBottom>
                                Freedom Cash Lenders will consider reasonable offers to resolve
                                this
                                account for less than you owe.
                            </Typography>
                            <Typography>
                                Your current balance is
                                ${loan.principalOutstanding}.
                            </Typography>
                        </Box>
                        <form
                            autoComplete="off"
                        >
                            <Grid container spacing={3} padding={1}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl required fullWidth>
                                        <InputLabel>Payment frequency</InputLabel>
                                        <Select
                                            name='payment-frequency'
                                            label="Payment frequency"
                                            defaultValue={""}
                                            onChange={handlePaymentFrequencyChange}
                                            value={paymentFrequency}
                                        >
                                            {frequency.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>Select a payment schedule you want to use</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="affordable-amount"
                                        label="How much can you afford?"
                                        value={amount}
                                        onChange={event => setAmount(event.target.value.replace(/[^0-9]/g, ''))}
                                        error={!validateAmount() && amount !== ''}
                                        helperText={amount && !validateAmount() ? "Amount must be at least $5" : getPaymentFrequencyHelperText1()}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="number-of-payments"
                                        label="Number of payments you want to make?"
                                        value={numberOfPayments}
                                        onChange={handleNumberOfPaymentsChange}
                                        error={!validateNumberOfPayments() && numberOfPayments !== ''}
                                        helperText={numberOfPayments && !validateNumberOfPayments() ? `Number of payments must be between 1 and ${maxPaymentsAllowed()}` : `Up to ${maxPaymentsAllowed()} payments allowed by your creditor`}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DatePicker
                                        label="First Payment Date"
                                        value={firstPaymentDate}
                                        onChange={setFirstPaymentDate}
                                        renderInput={(params) => <TextField {...params} fullWidth/>}
                                    />
                                </Grid>

                            </Grid>
                            {showAlert && !offerStatus && (
                                <Alert severity="warning">
                                    <Typography>
                                        A counter offer is an alternative resolution amount that may be presented
                                        back to you if your original offer was not accepted.
                                        You may receive up to 3 counter offers each time. To resolve your account,
                                        you can either accept one of these counter offers or present a different amount.
                                    </Typography>
                                </Alert>
                            )}
                            <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
                                <Button variant="outlined" sx={{mr: 1}}
                                        onClick={handleCalculateOffer}
                                >
                                    Calculate an offer
                                </Button>
                                <Box sx={{flex: "1 1 auto"}}/>
                                {offerCalculationProcess && !offerStatus ?
                                    <Button onClick={onNext}>
                                        Accept counter offer
                                    </Button>
                                    : <Button onClick={onNext} disabled={!offerCalculationProcess}>
                                        Next
                                    </Button>
                                }
                            </Box>
                        </form>
                    </Paper>
                </Grid>
                <Grid item sm={4} xs={12} padding={1}>
                    {offerCalculationProcess ? <OfferSummary activeStep={activeStep} offerStatus={offerStatus}/> :
                        <OfferPlaceholder/>}
                </Grid>
            </Grid>
        </>
    )
};
export default NegotiateStep1;
