import {
  Skeleton,
  Stack,
} from "@mui/material";
import React from "react";

const StepperLoading = () => {

  return (
    <Stack sx={{width: '75%', mx: 'auto'}}>
      <Stack direction="row" spacing={2} justifyContent="center" sx={{ width: '100%', mt: 4, mb: 4 }}>
        {/* Step 1 */}
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="rectangular" width={250} height={10} />
        {/* Step 2 */}
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="rectangular" width={250} height={10} />
        {/* Step 3 */}
        <Skeleton variant="circular" width={40} height={40} />
      </Stack>
      <Stack pt={2}>
        <Skeleton width="30%" height={23}/>
        <Skeleton width="90%" height={23}/>
        <Skeleton width="20%" height={23}/>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-around" alignItems="center" spacing={3} pt={5}>
        <Skeleton variant="rectangular" width={400} height={300}/>
        <Skeleton variant="rectangular" width={320} height={380}/>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-around" spacing={3} pt={3}>
        <Skeleton width="20%" height={23}/>
        <Skeleton width="10%" height={23}/>
      </Stack>
    </Stack>

  );
};

export default StepperLoading;
